<template>
	<div class="view-wrapper view-list-wrapper">
		<div class="page-top-bg-addition" />
		<div class="mcontainer">
			<h1 class="title page-title">{{ itemsName.mult }}</h1>
			<div class="view-content-card card content-row">
				<div class="card-content">
					<Filterbar
						:items-loading="itemsLoading"
						:filters="filters"
						@setFilters="setFilters"
					/>

					<ItemsTableContainer
						ref="ItemsTableContainer"
						:items-loading="itemsLoading"
						:items-saving="itemsSaving"
						:table-data="itemsList"
						:table-settings="tableSettings"
						:items-name="itemsName"
						@event="handleEvent"
					/>

					<PaginationContainer
						:items-name="itemsName"
						:filters="filters"
						:meta="meta"
						@setFilters="setFilters"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { itemsDataMixin, eventHandler } from '@/mixins';

export default {
	components: {
		Filterbar: () => import('../../components/Filterbar.vue'),
		ItemsTableContainer: () => import('../../components/ItemsTableContainer.vue'),
		PaginationContainer: () => import('../../components/PaginationContainer.vue')
	},
	mixins: [itemsDataMixin, eventHandler],

	computed: {
		...mapState({
			globalFilters: state => state.global.globalFilters,

			meta: state => state.attribute_groups.fetchedMeta,
			filters: state => state.attribute_groups.filters,
			itemsLoading: state => state.attribute_groups.isLoading,
			itemsSaving: state => state.attribute_groups.isSaving,
			itemsList: state => state.attribute_groups.itemsList
		}),

		itemsName() {
			return {
				one: 'Группа атрибутов',
				mult: 'Группы атрибутов'
			};
		},

		tableSettings() {
			return {
				columns: [
					{ prop: 'id', label: '№', sortable: true, width: 60 },
					{
						prop: 'title_ru',
						label: 'Название',
						sortable: true
					},
					{
						prop: 'category_title_ru',
						label: 'Категория товаров',
						sortable: true
						// meta: { sortBy: 'category_id' }
					},
					{ prop: 'category_id', label: 'id категории' }
				],
				operations: {
					edit: true,
					delete: true
				}
			};
		}
	},

	methods: {
		...mapActions({
			setup_navbar: 'setup_navbar',
			show_overlay: 'show_overlay',

			fetch_items: 'attribute_groups/fetch_attribute_groups',
			delete_item: 'attribute_groups/delete_attribute_group',

			set_items: 'attribute_groups/set_attribute_groups',
			set_filters: 'attribute_groups/set_attribute_groups_filters'
		})
	}
};
</script>
